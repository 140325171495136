import React, { forwardRef } from "react";
import { Container, Icon, Wrapper } from "./style";

const Input = forwardRef((props, ref) => (
  <Wrapper>
    <Icon>{props.icon}</Icon>
    <Container {...props} ref={ref} />
  </Wrapper>
));

export default Input;
