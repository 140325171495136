import React, { useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Container, Icons, MenuWrapper, Section, SelectAnt } from "./style";
import { Input, Button } from "./../Generic";
import { Dropdown } from "antd";
import { uzeReplace } from "../../hooks/useReplace";
import useSearch from "../../hooks/useSearch";
import { useState } from "react";

const baseUrl = process.env.REACT_APP_BASE_URL;

const Filter = () => {
  const [data, setData] = useState([1, 2, 3]);
  const [value, setValue] = useState("Select Category");

  useEffect(() => {
    if (query.get("category_id")) {
      let [res] = data.filter(
        (ctg) => ctg.id === Number(query.get("category_id"))
      );
      setValue(res?.name ?? "Select Category");
    }
  }, []);

  const location = useLocation();
  const navigate = useNavigate();
  const query = useSearch();

  const countryRef = useRef();
  const regionRef = useRef();
  const cityRef = useRef();
  const zipRef = useRef();

  const roomsRef = useRef();
  const sortRef = useRef();

  const minPriceRef = useRef();
  const maxPriceRef = useRef();

  const onChange = ({ target: { name, value } }) => {
    navigate(`${location.pathname}${uzeReplace(name, value)}`);
  };

  useEffect(() => {
    fetch(`${baseUrl}/categories/list`)
      .then((res) => res.json())
      .then((res) => {
        setData(res?.data || []);
      });
  }, []);

  useEffect(() => {
    let [d] = data?.filter(
      (ctg) => ctg.id === Number(query.get("category_id"))
    );
    d?.name && setValue(d?.name);
    !query.get('category_id') && setValue('Select Category');
  }, [location?.search, data]);

  const onChangeCategory = (category_id) => {
    navigate(`${location.pathname}${uzeReplace("category_id", category_id)}`);
  };

  const onChangeSort = (sort) => {
    navigate(`${location.pathname}${uzeReplace("sort", sort)}`);
  };

  const menu = (
    <MenuWrapper>
      <h1 className="subTitle">Address</h1>
      <Section>
        <Input
          defaultValue={query.get("country")}
          onChange={onChange}
          name="country"
          ref={countryRef}
          placeholder="Country"
        />
        <Input
          defaultValue={query.get("region")}
          onChange={onChange}
          name="region"
          ref={regionRef}
          placeholder="Region"
        />
        <Input
          defaultValue={query.get("address")}
          onChange={onChange}
          name="address"
          ref={cityRef}
          placeholder="City"
        />
        <Input
          defaultValue={query.get("zip_code")}
          onChange={onChange}
          name="zip_code"
          ref={zipRef}
          placeholder="Zip Code"
        />
      </Section>
      <h1 className="subTitle">Apartment info</h1>
      <Section>
        <Input
          defaultValue={query.get("rooms")}
          onChange={onChange}
          name="rooms"
          ref={roomsRef}
          placeholder="Rooms"
        />
        <SelectAnt
          defaultValue={query.get("sort") || "Select Sort"}
          onChange={onChangeSort}
        >
          <SelectAnt.Option value="">Select Sort</SelectAnt.Option>
          <SelectAnt.Option value="asc">O`suvchi</SelectAnt.Option>
          <SelectAnt.Option value="desc">Kamayuvchi</SelectAnt.Option>
        </SelectAnt>
        <SelectAnt defaultValue={value} onChange={onChangeCategory}>
          <SelectAnt.Option value="">Select Category</SelectAnt.Option>
          {data.map((value, index) => (
            <SelectAnt.Option value={value?.id || index + 1} key={index}>
              {value?.name || `Category ${index + 1}`}
            </SelectAnt.Option>
          ))}
        </SelectAnt>
      </Section>
      <h1 className="subTitle">Price</h1>
      <Section>
        <Input
          defaultValue={query.get("min_price")}
          onChange={onChange}
          name="min_price"
          ref={minPriceRef}
          placeholder="Min Price"
        />
        <Input
          defaultValue={query.get("max_price")}
          onChange={onChange}
          name="max_price"
          ref={maxPriceRef}
          placeholder="Max Price"
        />
      </Section>
    </MenuWrapper>
  );

  return (
    <Container>
      <Input
        icon={<Icons.Houses />}
        placeholder="Enter an address, neighborhood, city or ZIP code"
      />
      <Dropdown
        overlay={menu}
        placement="bottomRight"
        arrow={{ pointAtCenter: true }}
        trigger="click"
      >
        <Button type="light">
          <Icons.Filter /> Advanced
        </Button>
      </Dropdown>
      <Button>
        <Icons.Search />
        Search
      </Button>
    </Container>
  );
};

export default Filter;
