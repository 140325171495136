import React from "react";
import RootContext from "./../context/index";
import { navbar } from "./../utils/navbar";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import Navbar from "../components/Navbar";
import { QueryClient, QueryClientProvider } from "react-query";

const Root = () => {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <RootContext>
        <Router>
          <Routes>
            <Route element={<Navbar />}>
              {navbar.map(({ path, element, id }) => (
                <Route path={path} element={element} key={id} />
              ))}
            </Route>
            <Route path="/" element={<Navigate to="/home" />} />
            <Route path="*" element={<h1>404 | NOT FOUND</h1>} />
          </Routes>
        </Router>
      </RootContext>
    </QueryClientProvider>
  );
};

export default Root;
