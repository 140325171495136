import React, { useContext } from "react";
import { Container } from "./style";
import HouseCard from "../HouseCard";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { PropertiesContext } from "../../context/properties";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const Favourites = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const [, dispatch] = useContext(PropertiesContext);

  const { refetch, data } = useQuery(
    [search],
    async () => {
      const res = await fetch(`${baseUrl}/houses/getAll/favouriteList`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      return await res.json();
    },
    {
      onSuccess: (res) => {
        dispatch({ type: "refetch", payload: refetch });
      },
    }
  );

  const onSelect = (id) => {
    navigate(`/properties/${id}`);
  };

  return (
    <React.Fragment>
      <div className="title">Favourites</div>
      <div className="info" style={{ textAlign: "center" }}>
        Nulla quis curabitur velit volutpat auctor bibendum consectetur sit.
      </div>
      <Container>
        {data?.data?.length ? (
          data?.data.map((value) => {
            return (
              <HouseCard
                onClick={() => onSelect(value.id)}
                key={value.id}
                data={value}
              />
            );
          })
        ) : (
          <h1>No Data Found</h1>
        )}
      </Container>
    </React.Fragment>
  );
};

export default Favourites;

// I could be able to handle it with useQuery() custom hook
/* const [data, setData] = useState([]);
  const request = useRequest();

  useEffect(() => {
    request({ url: `/houses/getAll/favouriteList`, token: true }).then(
      (res) => {
        setData(res?.data);
      }
    );
  }, []); */

// false -  data.data
// true -  data
