import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Container, Link, Logo, Main, Menu, Section, Wrapper } from "./style";
import { navbar } from "./../../utils/navbar";
import { Button } from "../Generic";
import Filter from "../Filter";
import Footer from "../Footer";
import { Dropdown } from "antd";

function Navbar() {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const onClick = () => {
    navigate("/signin");
  };

  const onClickProfile = ({
    target: {
      dataset: { name },
    },
  }) => {
    if (name === "logout") {
      localStorage.removeItem("token");
      navigate("/");
    } else navigate(`/${name}`);
  };

  const menu = (
    <Menu>
      <Menu.Item data-name="my-profile" onClick={onClickProfile}>
        My profile
      </Menu.Item>
      {/* <Menu.Item data-name="my-properties" onClick={onClickProfile}>
        My Properties
      </Menu.Item> */}
      <Menu.Item data-name="favourites" onClick={onClickProfile}>
        Favourites
      </Menu.Item>
      <Menu.Item data-name="logout" onClick={onClickProfile}>
        Log out
      </Menu.Item>
    </Menu>
  );

  return (
    <Container>
      <Main>
        <Wrapper>
          <Section onClick={() => navigate("/home")} logo>
            <Logo /> <h3>Houzing</h3>
          </Section>
          <Section>
            {navbar.map(({ title, path, hidden }, index) =>
              !hidden ? (
                <Link to={path} key={index}>
                  {title}
                </Link>
              ) : null
            )}
          </Section>
          <Section>
            {token ? (
              <Dropdown
                overlay={menu}
                placement="topRight"
                arrow={{ pointAtCenter: true }}
                trigger="click"
              >
                <Button type="dark">
                  <div>Profile</div>
                </Button>
              </Dropdown>
            ) : (
              <Button type="dark" onClick={onClick}>
                Sign in
              </Button>
            )}
          </Section>
        </Wrapper>
      </Main>
      <Filter />
      <Outlet />
      <Footer />
    </Container>
  );
}

export default Navbar;
