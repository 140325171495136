import { lazy, Suspense } from "react";
import useId from "./../hooks/useId";
import Register from "./../components/Register";
import FavouritesPage from "../pages/Favourites";
import MyProfile from "../pages/MyProfile";
import AddNewHouse from "../pages/AddNewHouse";

const HomePage = lazy(() => import("./../pages/Home"));
const PropertiesPage = lazy(() => import("./../pages/Properties"));
const HouseItemPage = lazy(() => import("./../pages/HouseItem"));

export const navbar = [
  {
    id: useId,
    title: "Home",
    path: "/home",
    element: (
      <Suspense fallback={<>Loading...</>}>
        <HomePage />
      </Suspense>
    ),
    private: false,
    hidden: false,
  },
  {
    id: useId,
    title: "Properties",
    path: "/properties",
    element: (
      <Suspense fallback={<>Loading...</>}>
        <PropertiesPage />
      </Suspense>
    ),
    private: false,
    hidden: false,
  },
  {
    id: useId,
    title: "Single House",
    path: "/properties/:id",
    element: (
      <Suspense fallback={<>Loading...</>}>
        <HouseItemPage />
      </Suspense>
    ),
    private: false,
    hidden: true,
  },
  {
    id: useId,
    element: <MyProfile />,
    title: "My Profile",
    path: "/my-profile",
    private: true,
    hidden: true,
  },
  {
    id: useId,
    element: <AddNewHouse />,
    title: "Add New House",
    path: "/my-profile/newhouse",
    private: true,
    hidden: true,
  },
  {
    id: useId,
    element: <AddNewHouse />,
    title: "Add New House",
    path: "/my-profile/edithouse/:id",
    private: true,
    hidden: true,
  },
  {
    id: useId,
    title: "Sign In",
    path: "/signin",
    element: <Register />,
    private: false,
    hidden: true,
  },
  {
    id: useId,
    title: "Favourite",
    path: "/favourites",
    element: <FavouritesPage />,
    private: true,
    hidden: true,
  },
  {
    id: useId,
    title: "Sign Up",
    path: "/signup",
    element: <Register />,
    private: false,
    hidden: true,
  },
];
