import React, { useContext } from "react";
import { message } from "antd";
import { Container, Content, Details, Img, Icons, Divider } from "./style";
import noimg from "../../assets/img/noimg.jpeg";
import { PropertiesContext } from "./../../context/properties/index";
import { useRequest } from "./../../hooks/useRequest";
import addDots from "./../../resources/addDots";

export const HouseCard = ({ data = {}, gap, ...otherProps }) => {
  const {
    attachments,
    city,
    country,
    description,
    houseDetails,
    address,
    salePrice,
    price,
    category,
    favorite,
    id,
  } = data;
  const [state] = useContext(PropertiesContext);
  const request = useRequest();

  const save = (event) => {
    event?.stopPropagation();
    request({
      url: `/houses/addFavourite/${id}?favourite=${!favorite}`,
      method: "PUT",
      token: true,
    }).then((res) => {
      if (favorite) res?.success && message.warning("Successfully disliked");
      else res?.success && message.info("Successfully liked");
      state.refetch && state.refetch();
    });
  };

  return (
    <div style={{ display: "flex" }} {...otherProps}>
      <Container gap={gap}>
        <Img src={attachments ? attachments[0]?.imgPath : noimg} />
        <Content>
          <div className="subTitle inline">
            {addDots(`${city} ${country} ${description}`)}
          </div>
          <div className="info">
            {address || "Quincy St, Brooklyn, NY, USA"} -{" "}
            {category?.name || "Category"} {houseDetails?.room || 0}-rooms
          </div>
          <Details>
            <Details.Item>
              <Icons.Bed />
              <div className="info">Bed {houseDetails?.beds || 0}</div>
            </Details.Item>
            <Details.Item>
              <Icons.Bath />
              <div className="info">Bath {houseDetails?.bath || 0}</div>
            </Details.Item>
            <Details.Item>
              <Icons.Garage />
              <div className="info">Garage {houseDetails?.garage || 0} </div>
            </Details.Item>
            <Details.Item>
              <Icons.Ruler />
              <div className="info">Ruler {houseDetails?.area || 0}</div>
            </Details.Item>
          </Details>
        </Content>
        <Divider />
        <Content footer>
          <Details.Item footer>
            <div className="info">${salePrice ?? 0} / mo</div>
            <div className="subTitle">${price ?? 0} / mo</div>
          </Details.Item>
          <Details.Item row>
            <Icons.Resize />
            <Icons.Love onClick={save} favorite={favorite} />
          </Details.Item>
        </Content>
      </Container>
    </div>
  );
};

export default HouseCard;
