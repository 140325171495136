const addDots = (str) => {
  let n = 25;
  if (str.length > n) {
    let res = str.slice(0, n);
    for (let i = n; i < str.length; i++) {
      if (str[i] !== " ") res += str[i];
      else {
        res += "...";
        break;
      }
    }
    return res;
  } else {
    return str;
  }
};

export default addDots;
