import styled, { css } from "styled-components";

const getType = ({ type }) => {
  type = type?.toLowerCase();
  switch (type) {
    case "dark":
      return css`
        background: transparent;
        border: 1px solid #fff;
        color: #fff;
      `;
    case "light":
      return css`
        background: #fff;
        border: 1px solid #e6e9ec;
        color: #2d263b;
      `;
    default:
      return css`
        background: #0061df;
        border: none;
        color: #fff;
      `;
  }
};

const getWidth = ({ width }) => {
  if (!width) return "130px";
  else if (`${width}`.includes("%")) return "100%";
  else return `${width}px`;
};

export const Container = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  cursor: pointer;
  min-width: 120px;
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : "14px")};
  width: ${getWidth};
  height: ${({ height }) => (height ? `${height}px` : "44px")};
  ${getType}

  :active {
    opacity: 0.7;
  }
`;
