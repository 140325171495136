import styled, { css } from "styled-components";

const getType = ({ type }) => {
  type = type?.toLowerCase();
  switch (type) {
    case "dark":
      return css`
        background: transparent;
        border: 1px solid #fff;
        color: #fff;
      `;
    case "light":
      return css`
        background: #fff;
        border: 1px solid #e6e9ec;
        color: #2d263b;
      `;
    default:
      return css`
        background: #0061df;
        border: none;
        color: #fff;
      `;
  }
};

export const Container = styled.input`
  border-radius: 2px;
  outline: none;
  min-width: 120px;
  border: 1px solid #e6e9ec;
  padding-left: ${({ icon }) => (icon ? "35px" : "20px")};
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : "14px")};
  width: ${({ width }) => (width ? `${width}px` : "100%")};
  height: ${({ height }) => (height ? `${height}px` : "44px")};

  :focus {
    border: 1px solid #0061df;
  }
`;

export const Wrapper = styled.div`
  width: ${({ width }) => (width ? `${width}px` : "100%")};
  position: relative;
`;

export const Icon = styled.div`
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
`;
